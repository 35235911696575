import { Dayjs } from 'dayjs';
import { User } from './user';
import { RightI } from './right';
import { LicenseI } from './license';
import { SupplierI } from './supplier';

export interface FilmI {
  id: number;
  salesheetId: number;
  territories: FilmTerritoryData[];
  salesheetTerritories: FilmTerritoryData[];
  title: string;
  titleCN: string;
  licenseStartDate: string;
  licenseEndDate: string;
  salesType: string;
  salesRights: string;
  type: string;
  createdBy: User;
  updatedBy: User;
  licensesSource: string;
  coShare: string[];
  supplier: SupplierI;
  supplierId: number;
  createdAt: string;
  updatedAt: string;
  licenseFee: string;
  coShareName: string;
  coShareValue: string;
  languages: string;
  dateOfAgreement: string;
  genre: string[];
  director: string;
  cast: string;
  boxOffice: string;
  year: number;
  synopsis: string;
  trailer: string;
  countryOfOrigin: string;
  contractReferenceNumber: string;
  panelImage: {
    id: number;
    name: string;
    s3Url: string;
    type: string;
  }[];
  posterImage: {
    id: number;
    name: string;
    s3Url: string;
    type: string;
  }[];
  soldLicenses: LicenseI[] | any;
}

export type CustomerFilm = {
  id: number;
  name: string;
  licenses: { contractReferenceNumber: string; film: { id: number; title: string } }[];
};

export type FilmValues = {
  id: number;
  territories: FilmTerritoryValues[];
  title: string;
  titleCN: string;
  movieStartDate: string;
  movieEndDate: string;
  contractStartDate: string;
  contractEndDate: string;
  licenseStartDate: string;
  licenseEndDate: string;
  salesType: string;
  salesRights: string;
  type: string;
  createdBy: User;
  updatedBy: User;
  licensesSource: string;
  coShare: string;
  supplier: SupplierI;
  supplierId: number;
  distributeBy: string;
};

export type FilmTerritoryValues = {
  id: number;
  name: string;
  rights: number[];
  type: string | boolean;
  licenses: FilmTerritoryLicensesValues[];
};

export type FilmTerritoryLicensesValues = {
  id: number;
  rights: number[];
  territoriesLicenseRange: Dayjs[];
};

export type FilmTerritoryData = {
  id: number;
  name: string;
  excludedTerritories: string[];
  isWorldwide: boolean;
  typeOfRights: RightI[];
  type: string | boolean;
  licenses: LicenseI[];
  exclusiveRights: {
    id: number;
    startDate: string;
    endDate: string;
  }[];
  nonExclusiveRights: {
    id: number;
    startDate: string;
    endDate: string;
  }[];
};

export const GENRE_LIST = [
  { value: 'action', name: 'Action' },
  { value: 'comedy', name: 'Comedy' },
  { value: 'romance', name: 'Romance' },
  { value: 'thriller', name: 'Thriller' },
  { value: 'supernatural', name: 'Supernatural' },
  { value: 'horror', name: 'Horror' },
  { value: 'crime', name: 'Crime' },
];

export type FilmQueryParamsT = {
  start?: number;
  count?: number;
  filter?: {
    sortBy?: string;
    sortType?: string;
  };
};
