import { Modal, Pagination, Spin, message } from 'antd';
import React, { useState, useEffect, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { doDeleteFilm, doGetFilmsList, doImportFilms } from '../../api/films';
import { AccessControlI } from '../../interfaces';
import { FilmI, FilmQueryParamsT } from '../../interfaces/film';
import PrimaryButton from '../../shared/components/buttons/PrimaryButton';
import FilmsFilterForm from '../../shared/components/forms/film/Filter';
import { PAGE_SIZE } from '../../shared/constants';
import AddCircleOutlineIcon from '../../shared/svgs/AddCircleOutlineIcon';
import FilmsList from './components/List';
import './Films.scss';
import * as qs from 'qs';
import { getNestedQueryValues, handleAccessControl, handleFilterParams } from '../../utils/global';
import { CurrentUserI } from '../../interfaces/auth';
// import DownloadIcon from '../../shared/svgs/DownloadIcon';
// import SecondaryButton from '../../shared/components/buttons/SecondaryButton';
// import PublishIcon from '../../shared/svgs/PublishIcon';
import FilmImportForm from '../../shared/components/forms/film/ImportForm';
import SecondaryButton from '../../shared/components/buttons/SecondaryButton';
import PublishIcon from '../../shared/svgs/PublishIcon';
import FilmsSortingForm from './components/SortingForm';

const FilmsPage: React.FC = () => {
  const currentUser: CurrentUserI = useOutletContext();
  const access: AccessControlI = handleAccessControl(currentUser.roles, 'films');
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const [isImportVisible, setIsImportVisible] = useState<boolean>(false);
  const [isImporting, setIsImporting] = useState<boolean>(false);
  const [isErrorVisible, setIsErrorVisible] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const queryClient = useQueryClient();

  const urlSearch = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const queryParams = useMemo(() => {
    const start = urlSearch.get('start') ?? 0;
    const count = urlSearch.get('count') ?? PAGE_SIZE;
    const sortBy = urlSearch.get('filter[sortBy]') ?? 'createdAt';
    const sortType = urlSearch.get('filter[sortType]') ?? 'desc';
    const title = urlSearch.get('filter[title]');
    const licenseDateRange = urlSearch.get('filter[licenseDateRange]');
    const supplierId = getNestedQueryValues(urlSearch, 'filter[supplierId]');
    const rightIds = getNestedQueryValues(urlSearch, 'filter[rightIds]');
    const territories = getNestedQueryValues(urlSearch, 'filter[territories]');
    return {
      start,
      count,
      filter: {
        sortBy,
        sortType,
        title,
        licenseDateRange,
        supplierId,
        territories,
        rightIds,
      },
    } as FilmQueryParamsT;
  }, [urlSearch]);
  const pushTo = (pushProps: any) => {
    localStorage.setItem('filmListProps', JSON.stringify({ start: pushProps?.start, count: pushProps?.count }));
    navigate(
      {
        pathname,
        search: qs.stringify(pushProps, { encode: false, skipNulls: true }),
      },
      { state: pushProps },
    );
  };

  const {
    data,
    isLoading,
    refetch: refetchFilmList,
  } = useQuery(['films', 'list', queryParams], () => doGetFilmsList(queryParams), { refetchOnMount: true });

  const currentPage = queryParams.start ? Math.round((Number(queryParams.start) - 1) / PAGE_SIZE + 1) : 1;

  const pagination: any = {
    current: currentPage,
    pageSize: PAGE_SIZE,
    total: data?.total || 0,
    showSizeChanger: false,
    showTotal: (total: string) => `${total} Items`,
    onChange: (page: number) => handleFilter(page),
  };

  const setQueryParams = (params: FilmQueryParamsT) => {
    localStorage.setItem('filmListProps', JSON.stringify({ start: queryParams.start, count: queryParams?.count }));
    const query = handleFilterParams(params);
    navigate(`?${query}`);
  };

  const handleSorting = (values: { sortBy: string; sortType: string }) => {
    setQueryParams({
      ...queryParams,
      filter: { ...queryParams.filter, sortBy: values.sortBy, sortType: values.sortType },
    });
  };

  const handleFilter = (page: number) => {
    const currentPage = page;
    const offset = currentPage ? (currentPage === 1 ? 0 : (Number(currentPage) - 1) * PAGE_SIZE) : 0;
    setQueryParams({ start: Number(offset), count: PAGE_SIZE, filter: { ...queryParams.filter } });
  };

  const handleFilterSubmit = (values: any) => {
    setQueryParams({ start: 0, count: PAGE_SIZE, filter: { ...queryParams.filter, ...values } });
  };

  const { mutateAsync: mutationDelete } = useMutation(doDeleteFilm, {
    onSuccess: async () => {
      message.info('Deleted!');
      queryClient.invalidateQueries({
        queryKey: ['films', 'list'],
      });
      navigate(`/films`, { replace: true });
    },
  });

  const handleClickDelete = (user: FilmI) => {
    mutationDelete(user.id);
  };

  const handleResetFilter = () => {
    const newParams = { start: 0, count: PAGE_SIZE, filter: { sortBy: 'createdAt', sortType: 'desc' } };
    pushTo(newParams);
    setQueryParams(newParams);
  };

  const onImportExcelClick = () => {
    setIsImportVisible(true);
  };

  const handleImportCancel = () => {
    setIsImportVisible(false);
  };

  // const onErrorClick = () => {
  //   setIsImportVisible(true);
  // };

  const handleErrorCancel = () => {
    setIsErrorVisible(false);
  };

  useEffect(() => {
    refetchFilmList();
    const filmListProps = localStorage.getItem('filmListProps');
    const fromPath = localStorage.getItem('from');
    if (filmListProps && (fromPath === 'film-update' || fromPath === 'film-detail' || fromPath === 'film-create')) {
      const jsonFilmListProps = JSON.parse(filmListProps);
      pushTo(jsonFilmListProps);
      setQueryParams({
        ...queryParams,
        start: jsonFilmListProps?.start,
        count: jsonFilmListProps?.count,
        filter: jsonFilmListProps?.filter,
      });
      // localStorage.removeItem('filmListProps');
      localStorage.removeItem('from');
    }
  }, []);

  const { mutateAsync: importMutate } = useMutation(doImportFilms, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['films', 'list'],
      });
      // message.info('Imported successfully!');
      navigate(`/films`, { replace: true });
      setIsImporting(false);
    },
    onError: () => {
      message.error('Update film failed! Please try again');
      setIsImporting(false);
    },
  });

  const handleImportSubmit = async (formData: any) => {
    // importMutate(formData);
    // setIsImporting(true);
    try {
      const result = await importMutate(formData);
      if (typeof result.message === 'object') {
        setIsErrorVisible(true);
        setErrorMessages(result.message);
      } else {
        message.info('Imported successfully!');
      }
    } catch (error) {
      console.error('Mutation failed:', error);
    }
  };
  return (
    <Spin spinning={isLoading}>
      <div className="films-container">
        <div className="page-container">
          <div className="page-header">
            <div>
              {/* <Link to="/documents/importFilms.xlsx" target="_blank" download>
                <Button type="primary" className="btn-primary btn-download-sample">
                  Download Sample <DownloadIcon />
                </Button>
              </Link> */}
            </div>
            <div className="page-header-actions">
              {access.isCanCreate && (
                <Link to="/films/new">
                  <PrimaryButton label="Create Film" icon={<AddCircleOutlineIcon />} />
                </Link>
              )}
              <SecondaryButton label="Import Excel" icon={<PublishIcon />} onClick={onImportExcelClick} />
            </div>
          </div>
          <div className="page-content page-content-filer">
            <FilmsFilterForm
              handleFilter={handleFilterSubmit}
              handleReset={handleResetFilter}
              setQueryParams={setQueryParams}
              queryParams={queryParams}
            />
          </div>
          <FilmsSortingForm queryParams={queryParams} handleSorting={handleSorting} />
          <FilmsList
            films={data?.data}
            handleClickDelete={handleClickDelete}
            access={access}
            currentUser={currentUser}
          />
          <div className="pagination-container">
            <Pagination {...pagination} />
          </div>
        </div>
      </div>
      <Modal open={isImportVisible} onOk={handleImportCancel} onCancel={handleImportCancel} footer={null} width={560}>
        <FilmImportForm isLoading={isImporting} handleSubmit={handleImportSubmit} handleCancel={handleImportCancel} />
      </Modal>
      <Modal open={isErrorVisible} onOk={handleErrorCancel} onCancel={handleErrorCancel} footer={null} width={560}>
        <ul>
          {errorMessages.map((msg, index) => (
            <li key={index}>{msg}</li>
          ))}
        </ul>
      </Modal>
    </Spin>
  );
};

export default FilmsPage;
