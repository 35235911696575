import { Select } from 'antd';
import React from 'react';
import './SortingForm.scss';
import { FilmQueryParamsT } from '../../../interfaces/film';

type Props = {
  queryParams: FilmQueryParamsT;
  handleSorting: (_: { sortBy: string; sortType: string }) => void;
};
const FilmsSortingForm: React.FC<Props> = ({ queryParams, handleSorting }) => {
  const handleSortByChange = (value: string) => {
    const [orderBy, orderType] = value.split('-');
    handleSorting({ sortBy: orderBy, sortType: orderType });
  };

  return (
    <div className="films-sorting-container">
      <span>Sort by:</span>
      <Select
        onChange={handleSortByChange}
        placeholder="Sort by"
        value={`${queryParams?.filter?.sortBy}-${queryParams?.filter?.sortType}`}
        options={[
          { value: 'title-asc', label: 'Title (ASC)' },
          { value: 'title-desc', label: 'Title (DESC)' },
          { value: 'year-asc', label: 'Year (ASC)' },
          { value: 'year-desc', label: 'Year (DESC)' },
          { value: 'createdAt-desc', label: 'Created At (DESC)' },
          { value: 'createdAt-asc', label: 'Created At (ASC)' },
        ]}
      />
    </div>
  );
};

export default FilmsSortingForm;
